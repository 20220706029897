import React, { useEffect, useRef } from 'react';

const PDFPage = ({ pdf, pageNumber, containerWidth }) => {
  const canvasRef = useRef(null);
  const renderTaskRef = useRef(null);

  useEffect(() => {
    const renderPage = async () => {
      // Cancel any ongoing render task
      if (renderTaskRef.current) {
        renderTaskRef.current.cancel();
      }

      const page = await pdf.getPage(pageNumber);
      const viewport = page.getViewport({ scale: 1 });
      const scale = containerWidth / viewport.width;
      const scaledViewport = page.getViewport({ scale });

      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      canvas.height = scaledViewport.height;
      canvas.width = scaledViewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: scaledViewport,
      };

      // Start rendering the page
      renderTaskRef.current = page.render(renderContext);

      try {
        await renderTaskRef.current.promise;
      } catch (error) {
        if (error.name === 'RenderingCancelledException') {
          // Rendering was cancelled, no action needed
        } else {
          console.error('Error rendering page:', error);
        }
      }
    };

    renderPage();

    // Cleanup function to cancel renderTask when component unmounts or dependencies change
    return () => {
      if (renderTaskRef.current) {
        renderTaskRef.current.cancel();
      }
    };
  }, [pdf, pageNumber, containerWidth]);

  return <canvas ref={canvasRef} style={{ marginBottom: '10px' }} />;
};

export default PDFPage;
