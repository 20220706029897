import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import { Box } from '@mui/material';
import PDFPage from './PDFPage';

// Ensure PDF worker is loaded
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const PDFViewer = ({ file }) => {
  const [pdf, setPdf] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [containerWidth, setContainerWidth] = useState(null); // Start with null
  const containerRef = useRef(null);
  const scrollIntervalRef = useRef(null);

  useEffect(() => {
    const loadPDF = async () => {
      try {
        const loadingTask = pdfjsLib.getDocument(URL.createObjectURL(file));
        const pdfDocument = await loadingTask.promise;
        setPdf(pdfDocument);
        setTotalPages(pdfDocument.numPages);
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    };

    loadPDF();
  }, [file]);

  useLayoutEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth);
    }
  }, []);

  useEffect(() => {
    const startAutoScroll = () => {
      const container = containerRef.current;
      if (!container) return;

      container.scrollTop = 0;
      const maxScrollTop = container.scrollHeight - container.clientHeight;
      const scrollSpeed = 1; // Adjust this value to change the scroll speed

      scrollIntervalRef.current = setInterval(() => {
        if (container.scrollTop >= maxScrollTop) {
          clearInterval(scrollIntervalRef.current);
        } else {
          container.scrollTop += scrollSpeed;
        }
      }, 16); // ~60 fps
    };

    if (pdf) {
      startAutoScroll();
    }

    return () => {
      if (scrollIntervalRef.current) {
        clearInterval(scrollIntervalRef.current);
      }
    };
  }, [pdf]);

  return (
    <Box
      ref={containerRef}
      sx={{
        height: '500px',
        overflowY: 'auto',
        border: '1px solid #ddd',
      }}
    >
      {pdf &&
        containerWidth && // Ensure containerWidth is available
        Array.from({ length: totalPages }, (_, index) => (
          <PDFPage
            key={`page_${index + 1}`}
            pdf={pdf}
            pageNumber={index + 1}
            containerWidth={containerWidth}
          />
        ))}
    </Box>
  );
};

export default PDFViewer;
