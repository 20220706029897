// src/components/NotFound.js

import React, {useContext} from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/authContext';

function NotFound() {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);

  const handleGoHome = () => {
    if (isAuthenticated){
      navigate('/chat');
    }else{
      navigate('/');
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ textAlign: 'center', mt: 10 }}>
        <Typography variant="h3" gutterBottom>
          404 - Page Not Found
        </Typography>
        <Typography variant="body1" gutterBottom>
          Oops! The page you&apos;re looking for doesn&apos;t exist.
        </Typography>
        <Button variant="contained" color="primary" onClick={handleGoHome}>
          Go to Dashboard
        </Button>
      </Box>
    </Container>
  );
}

export default NotFound;
