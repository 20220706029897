import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import PDFViewer from './PDFViewer';

const FilePreviewDialog = ({ open, file, onConfirm, onCancel }) => {
  const [isImage, setIsImage] = useState(false);
  const [isPDF, setIsPDF] = useState(false);

  useEffect(() => {
    if (file) {
      const fileType = file.type;
      setIsImage(fileType.startsWith('image/'));
      setIsPDF(fileType === 'application/pdf');
    }
  }, [file]);

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="md" fullWidth>
      <DialogTitle>Preview File</DialogTitle>
      <DialogContent
        style={{
          overflowY: 'hidden',
        }}
      >
        {isImage && (
          <div
            style={{
              overflowY: 'auto',
              maxHeight: '70vh',
              textAlign: 'center',
            }}
          >
            <img
              src={URL.createObjectURL(file)}
              alt="Preview"
              style={{ width: '100%', display: 'block' }}
            />
          </div>
        )}
        {isPDF && <PDFViewer file={file} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} variant="contained" color="primary">Yes, It is correct</Button>
        <Button onClick={onCancel} >
          No, It is incorrect
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilePreviewDialog;