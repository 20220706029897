import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Box mb={4}>
          <Typography variant="h4" gutterBottom align="center">
            Assembli App Privacy Policy
          </Typography>
          <Typography variant="subtitle1" gutterBottom align="center" color="text.secondary">
            Effective Date: November 12, 2024
          </Typography>
        </Box>

        <Typography paragraph>
          This Privacy Policy explains how Assembli, Inc. (&quot;Assembli&quot;, &quot;we&quot;, &quot;us&quot;, or &quot;our&quot;) collects, uses, and discloses information about you when you use our Assembli mobile and web application (the &quot;App&quot;).
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Information We Collect
        </Typography>
        
        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
          Information You Provide to Us
        </Typography>
        <Typography paragraph>
          When you sign up for an Assembli account, you have the option to authenticate using your Google account. If you choose to sign in with Google, we will collect the following information:
        </Typography>
        <Box component="ul" sx={{ pl: 4 }}>
          <li>Your Google account email address</li>
          <li>Your name as it appears on your Google account</li>
          <li>Your Google account profile picture (if you have one)</li>
        </Box>
        <Typography paragraph>
          We use this information to create and authenticate your Assembli account.
        </Typography>

        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', mt: 2 }}>
          Information We Collect Automatically
        </Typography>
        <Typography paragraph>
          We also collect certain information automatically when you use the App, such as your device type, operating system, and App usage data. However, we do not link this automatically collected information to the personal information we collect through Google authentication.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          How We Use Your Information
        </Typography>
        <Typography paragraph>
          We use the information we collect to:
        </Typography>
        <Box component="ul" sx={{ pl: 4 }}>
          <li>Provide, maintain, and improve the App</li>
          <li>Authenticate your account and enable you to access the App</li>
          <li>Communicate with you about the App, including to send you product updates and service announcements</li>
          <li>Analyze usage of the App and improve the user experience</li>
        </Box>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Sharing Your Information
        </Typography>
        <Typography paragraph>
          We do not share, sell, or otherwise disclose your personal information to third parties, except in the following circumstances:
        </Typography>
        <Box component="ul" sx={{ pl: 4 }}>
          <li>With service providers who perform services on our behalf, such as hosting and web analytics providers, but only to the extent necessary to perform these services</li>
          <li>If we believe disclosure is required by law or to protect the rights, property, or safety of Assembli, our users, or others</li>
          <li>In the event of a merger, acquisition, or sale of all or a portion of our business or assets, in which case the new owner may use your personal information in the same way as set forth in this policy</li>
        </Box>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Your Choices
        </Typography>
        <Typography paragraph>
          You can control certain aspects of information collection and use by going to the settings page within the App. For example, you can disconnect your Google account from your Assembli account at any time.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Data Security
        </Typography>
        <Typography paragraph>
          We implement reasonable security measures to help protect the security of your information. However, no method of transmission over the internet or method of electronic storage is 100% secure, so we cannot guarantee its absolute security.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Changes to This Privacy Policy
        </Typography>
        <Typography paragraph>
          We may update this Privacy Policy from time to time. If we make material changes, we will notify you by email (using the email address specified in your account) or by means of a notice in the App prior to the change becoming effective.
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
          Contact Us
        </Typography>
        <Typography paragraph>
          If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at privacy@assembli.com.
        </Typography>
      </Paper>
    </Container>
  );
};

export default PrivacyPolicy;