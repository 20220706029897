import axios from 'axios';
import apiAxios from './axiosConfig';

const API_URL = process.env.REACT_APP_API_URL;

// Username/Password Login
export const usernamePasswordLogin = async (username, password) => {
  try {
    const response = await apiAxios.post(`${API_URL}/login`, {
      username,
      password,
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data; // Should include 'access_token'
  } catch (error) {
    console.error('Error during login:', error.response?.data);
    throw error;
  }
};

// Google Login
export const login = async (accessToken) => {
  try {
    const response = await apiAxios.post(`${API_URL}/login/callback`, { token: accessToken });
    return response.data; // Should include 'access_token'
  } catch (error) {
    console.error('Error during login:', error.response?.data);
    throw error;
  }
};

// Google Signup
export const signupGoogle = async (accessToken, companyName) => {
  try {
    const response = await apiAxios.post(`${API_URL}/signup/callback`, { token: accessToken, company_name: companyName });
    return response.data; // Should include 'access_token'
  } catch (error) {
    console.error('Error during signup:', error.response?.data);
    throw error;
  }
};

// Logout
export const logout = async () => {
  try {
    const token = localStorage.getItem('access_token');
    await apiAxios.post(`${API_URL}/logout`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    console.error('Error during logout:', error.response?.data);
  } finally {
    localStorage.removeItem('access_token');
  }
};

// Normal Signup
export const signup = async (username, email, password, companyName) => {
  try {
    const response = await apiAxios.post(`${API_URL}/signup`, {
      username,
      email,
      password,
      company_name: companyName,
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data; // Should include 'access_token'
  } catch (error) {
    console.error('Error during signup:', error.response?.data);
    throw error;
  }
};
