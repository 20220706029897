// src/axiosConfig.js

import axios from 'axios';
import { getCookie } from '../utils/cookieHelper';

const API_URL = process.env.REACT_APP_API_URL;

const apiAxios = axios.create({
    baseURL: API_URL,
    withCredentials: true, // Ensure cookies are sent with requests
    // headers: {
    //     'Content-Type': 'application/json'
    // }
});

// Add a request interceptor
apiAxios.interceptors.request.use(
    (config) => {
        // Add authorization token if it exists
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        // Only add CSRF token for non-GET requests
        if (config.method !== 'get') {
            const csrfToken = getCookie('csrf_token');
            if (csrfToken) {
                config.headers['X-CSRFToken'] = csrfToken;
            }
        }

        
        return config;
    },
    (error) => Promise.reject(error)
);

// Handle response errors
apiAxios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            // Check if the request URL is not an authentication endpoint
            if (
              !error.config.url.includes('/login') &&
              !error.config.url.includes('/signup') &&
              !error.config.url.includes('/login/callback') &&
              !error.config.url.includes('/signup/callback')
            ) {
              // Only navigate to '/login' if not already on the login page
              if (window.location.pathname !== '/login') {
                window.location.href = '/login';
              }
            }
        }
        return Promise.reject(error);
    }
);

export default apiAxios;
