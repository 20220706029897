// src/services/jobService.js

import apiAxios from './axiosConfig'; // Use the configured Axios instance

// Create a new job
export const createJob = async (prompt, fileUrl) => {
  try {

    const payload = {
      prompt: prompt,
      file_url: fileUrl,
    };

    const response = await apiAxios.post('/jobs', payload);
    return response.data;

  } catch (error) {
    console.error('Error creating job:', error.response?.data);
    // Return an error object to be handled by the caller
    if (error.response && error.response.data) {
      return { error: error.response.data.error };
    } else {
      return { error: 'An unexpected error occurred.' };
    }
  }
};

// Get list of jobs
export const getJobs = async () => {
  try {
    const response = await apiAxios.get('/jobs', {
      // Include authentication headers if needed
      // headers: {
      //   'Authorization': `Bearer ${token}`,
      // },
    });
    return response.data; // Array of jobs
  } catch (error) {
    console.error('Error fetching jobs:', error.response?.data);
    // Return an error object to be handled by the caller
    if (error.response && error.response.data) {
      return { error: error.response.data.error };
    } else {
      return { error: 'Failed to fetch jobs. Please try again.' };
    }
  }
};


// Request a signed policy for file upload
export const requestSignedPolicy = async (projectId, fileName, contentType) => {
  try {
    const response = await apiAxios.post(`/projects/${projectId}/upload_policy`, {
      file_name: fileName,
      content_type: contentType,
    });
    return response.data; // Contains 'url' and 'fields'
  } catch (error) {
    console.error('Error requesting signed policy:', error.response?.data);
    throw error;
  }
};

// Upload file using the signed policy with progress tracking
export const uploadFileWithPolicy = async (policyData, file, setProgress) => {
  try {
    const formData = new FormData();
    Object.entries(policyData.fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('file', file);

    const response = await apiAxios.post(policyData.url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Ensure correct Content-Type
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
      },
    });

    if (response.status !== 204 && response.status !== 201) {
      throw new Error(`Failed to upload file: ${response.statusText}`);
    }

    return response;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};
