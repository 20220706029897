// src/components/AlertMessage.jsx

import React, { useEffect, useState } from 'react';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

function AlertMessage({ message, type, onClose }) {
  const [open, setOpen] = useState(false);
  const [displayMessage, setDisplayMessage] = useState('');

  // When 'message' changes, show the alert
  useEffect(() => {
    if (message) {
      setDisplayMessage(message);
      setOpen(true); // Show the message
    }
  }, [message]);

  // When 'open' is true, start a timer to hide the alert after 4 seconds
  useEffect(() => {
    let timer;
    if (open) {
      timer = setTimeout(() => {
        setOpen(false); // Start fade-out
      }, 4000); // Display for 4 seconds
    }
    return () => clearTimeout(timer);
  }, [open]);

  return (
    <Fade
      in={open}
      timeout={{ enter: 500, exit: 3000 }}
      unmountOnExit
      onExited={() => {
        // When fade-out is complete, notify parent component
        if (onClose) {
          onClose();
        }
      }}
    >
      <Typography
        color={type === 'error' ? 'error.main' : 'success.main'}
        variant="body2"
        align="center"
        sx={{ mt: 2 }}
      >
        {displayMessage}
      </Typography>
    </Fade>
  );
}

AlertMessage.propTypes = {
  message: PropTypes.string,
  type: PropTypes.oneOf(['error', 'success', 'warning', 'info']).isRequired,
  onClose: PropTypes.func,
};

AlertMessage.defaultProps = {
  message: '',
  onClose: null,
};

export default AlertMessage;
