import React, { useState, useContext } from 'react';
import { TextField, Button, Typography, InputAdornment, IconButton, Link } from '@mui/material';
import { Visibility, VisibilityOff, HelpOutline } from '@mui/icons-material';
import { AuthContext } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';
import CardComponent from '../CardComponent';
import AlertMessage from '../AlertMessage';
import Navbar from '../Navbar/Navbar';

function Signup() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { signup } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleSignup = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');

    if (!companyName.trim()) {
      setError("Company name is required");
      return;
    }

    try {
      const response = await signup(username, email, password, companyName);
      if (response) {
        setSuccessMessage('User created successfully');
        navigate('/chat');
      }
    } catch (error) {
      if (error.response?.data?.error === "Password does not meet security requirements") {
        setError(
          `Password must be at least 12 characters long and contain:
          - At least one uppercase letter
          - At least one lowercase letter
          - At least one digit
          - At least one special character`
        );
      } else {
        setError(error.response?.data?.error || 'Signup failed');
      }
    }
  };

  return (
    <div>
      <Navbar />
      <CardComponent title="Sign Up">
        <form onSubmit={handleSignup} className="space-y-4">
          <TextField
            label="Company Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            required
          />
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <TextField
            label="Email"
            type="email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            label="Password"
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff className="h-5 w-5" /> : <Visibility className="h-5 w-5" />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <div className="mt-2 text-sm text-gray-600">
            <Typography variant="body2" color="text.secondary">
              Password must contain:
              <ul className="list-disc pl-6 mt-1 space-y-1">
                <li>At least 12 characters</li>
                <li>At least one uppercase letter</li>
                <li>At least one lowercase letter</li>
                <li>At least one number</li>
                <li>At least one special character</li>
              </ul>
            </Typography>
          </div>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 3 }}
          >
            Sign Up
          </Button>
        </form>

        {/* {error && <AlertMessage message={error} type="error" />}
        {successMessage && <AlertMessage message={successMessage} type="success" />} */}

        <AlertMessage message={error} type="error" onClose={() => setError('')} />


        <div className="mt-6 flex items-center justify-center space-x-2 text-gray-600">
          {/* <HelpOutline className="h-5 w-5" /> */}
          <Typography variant="body2">
            Need help? Contact support at{' '}
            <Link
              href="mailto:support@assembli.ai"
              underline="hover"
              className="text-blue-600 hover:text-blue-800"
            >
              support@assembli.ai
            </Link>
          </Typography>
        </div>

        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          className="mt-4"
        >
          Already have an account?{' '}
          <Link
            href="/login"
            underline="hover"
            className="text-blue-600 hover:text-blue-800"
          >
            Login here
          </Link>
        </Typography>
      </CardComponent>
    </div>
  );
}

export default Signup;