// src/components/Login.jsx

import React, { useState, useContext } from 'react';
import { TextField, Button, InputAdornment, IconButton, Typography, Link } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { AuthContext } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';
import CardComponent from '../CardComponent';
import AlertMessage from '../AlertMessage';
import Navbar from '../Navbar/Navbar';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { usernamePassLogin } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleLogin = async (e) => {
    e.preventDefault();
    // Do not reset error here
    // setError('');

    try {
      const user = await usernamePassLogin(username, password);
      if (user) {
        navigate('/chat');
      } else {
        setError('Invalid username or password');
      }
    } catch (err) {
      setError(err.response?.data?.error || 'Login failed');
    }
  };

  return (
    <div>
      <Navbar />
      <CardComponent title="Login">
        <form onSubmit={handleLogin} className="space-y-4">
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <TextField
            label="Password"
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOff className="h-5 w-5" />
                    ) : (
                      <Visibility className="h-5 w-5" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 3 }}
          >
            Login
          </Button>
        </form>

        {/* Always render AlertMessage */}
        <AlertMessage message={error} type="error" onClose={() => setError('')} />

        <div className="mt-6 flex items-center justify-center space-x-2 text-gray-600">
          <Typography variant="body2">
            Need help? Contact support at{' '}
            <Link
              href="mailto:support@assembli.ai"
              underline="hover"
              className="text-blue-600 hover:text-blue-800"
            >
              support@assembli.ai
            </Link>
          </Typography>
        </div>

        <Typography
          variant="body2"
          color="text.secondary"
          align="center"
          className="mt-4"
        >
          Don&apos;t have an account?{' '}
          <Link
            href="/signup"
            underline="hover"
            className="text-blue-600 hover:text-blue-800"
          >
            Sign up here
          </Link>
        </Typography>
      </CardComponent>
    </div>
  );
}

export default Login;