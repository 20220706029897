import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const CardComponent = ({ title, children }) => {
  return (
    <Card sx={{ maxWidth: 400, margin: 'auto', mt: 10, boxShadow: 3 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom align="center">
          {title}
        </Typography>
        {children}
      </CardContent>
    </Card>
  );
};

export default CardComponent;
