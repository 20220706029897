import React, { createContext, useState } from 'react';
import {
  login as loginService,
  logout as logoutService,
  signup as signupService,
  usernamePasswordLogin,
  signupGoogle as signupGoogleService,
} from '../services/authService';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('access_token'));
  const [companyName, setCompanyName] = useState('Assembli AI');
  const navigate = useNavigate();

  const login = async (accessToken) => {
    try {
      const response = await loginService(accessToken);
      if (response.access_token) { // Ensure 'access_token' is used
        setIsAuthenticated(true);
        localStorage.setItem('access_token', response.access_token);
        return response.user;
      }
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
    return null;
  };

  const signupGoogle = async (accessToken, companyName) => {
    try {
      const response = await signupGoogleService(accessToken, companyName);
      if (response.access_token) { // Ensure 'access_token' is used
        setIsAuthenticated(true);
        localStorage.setItem('access_token', response.access_token);
        return response.user;
      }
    } catch (error) {
      console.error('Signup failed:', error);
      throw error;
    }
    return null;
  };

  const usernamePassLogin = async (username, password) => {
    try {
      const response = await usernamePasswordLogin(username, password);

      if (response.access_token) { // Ensure 'access_token' is used
        setIsAuthenticated(true);
        localStorage.setItem('access_token', response.access_token);
        return response;
      }
    } catch (error) {
      console.error('Username/Password login failed:', error);
      throw error;
    }
    return null;
  };

  const logout = async () => {
    try {
      await logoutService();
      setIsAuthenticated(false);
    } catch (error) {
      console.error('Logout failed:', error);
    } finally {
      setIsAuthenticated(false);
      localStorage.removeItem('access_token');
      navigate('/login');
    }
  };

  const signup = async (username, email, password, companyName) => {
    try {
      const response = await signupService(username, email, password, companyName);

      if (response.access_token) { // Ensure 'access_token' is used
        setIsAuthenticated(true);
        localStorage.setItem('access_token', response.access_token);
        return response;
      }
    } catch (error) {
      console.error('Signup failed:', error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, usernamePassLogin, logout, signup, signupGoogle }}>
      {children}
    </AuthContext.Provider>
  );
};
