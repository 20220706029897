import React from 'react';
import { Drawer, List, ListItem, ListItemText } from '@mui/material';
import '../../styles/Sidebar.css';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

function Sidebar({
  open,
  onClose,
  jobs=[],
  currentJob,
  handleJobClick,
  // handleNewJob,
  handleRenameJob,
}) {

  const navigate = useNavigate();

  const handleNewJobPage = async (job) => {
    navigate('/chat');
  };

  return (
    <Drawer
      variant={useMediaQuery('(min-width:769px)') ? 'permanent' : 'temporary'} // Changed to 'permanent' for fixed sidebar
      anchor="left"
      open={open}
      sx={{
        '& .MuiDrawer-paper': {
          width: { xs: '80%', sm: 240 }, // Responsive width
          boxSizing: 'border-box',
        },
      }}      
      classes={{ paper: 'sidebar-paper' }}
    >
      <div className="sidebar-header">
        <button className="new-chat-button" onClick={handleNewJobPage}>
          + New Job
        </button>
      </div>
      <div className="sidebar-content">
        <List>
          {jobs.map((job) => (
            <ListItem
              button
              key={job.id}
              selected={currentJob && currentJob.id === job.id}
              onClick={() => {
                handleJobClick(job);
                onClose(); // Close the sidebar after selecting a job
              }}
            >
              <ListItemText className='ListItemText' primary={job.job_name || `Job ${job.id}`} />
              {/* <JobMenu job={job} onRename={handleRenameJob} /> */}
            </ListItem>
          ))}
        </List>
      </div>
    </Drawer>
  );
}

export default Sidebar;