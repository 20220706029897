// src/components/ChatDashboard/PromptInput.jsx

import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  IconButton,
  Snackbar,
  Alert,
} from '@mui/material';
import { AttachFile } from '@mui/icons-material';
import assembliLogo from '../../static/logo.png';
import FilePreviewDialog from './FilePreviewDialog';
import { getUploadPolicy, uploadToGCS } from '../../services/uploadService';
import CircularProgress from '@mui/material/CircularProgress';

const PromptInput = ({ onSubmit }) => {
  const [prompt, setPrompt] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileForPreview, setFileForPreview] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Validate file type and size
      const allowedExtensions = ['png', 'jpg', 'jpeg', 'gif', 'pdf', 'doc', 'docx'];
      const maxFileSize = 200 * 1024 * 1024; // 200 MB

      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        setError('File type not allowed');
        setSnackbarOpen(true);
        return;
      }
      if (file.size > maxFileSize) {
        setError('File size exceeds the 200 MB limit');
        setSnackbarOpen(true);
        return;
      }

      setFileForPreview(file);
      setIsDialogOpen(true);
    }
  };

  const handleConfirm = () => {
    setSelectedFile(fileForPreview);
    setIsDialogOpen(false);
    setFileForPreview(null);
  };

  const handleCancel = () => {
    setFileForPreview(null);
    setIsDialogOpen(false);
    setSelectedFile(null); // Clear selectedFile when user clicks "No"
  };

  const truncateFileName = (fileName, maxLength = 20) => {
    if (!fileName) return 'Upload your plan';
    if (fileName.length <= maxLength) return fileName;
    return fileName.substring(0, maxLength - 3) + '...';
  };

  // Replicate the server-side validate_content logic on the client side.
  const validatePrompt = (content) => {
    const prohibitedWords = ['spam', 'scam', 'hack', 'malware', 'phishing', 'virus'];
    const codePatterns = [
      /`{3}[\s\S]*?`{3}/,   // Triple backticks for code blocks
      /<[^>]+>/,            // HTML tags
      // /\bimport\b/,
      // /\bfrom\b/,
      // /\bdef\b/,
      // /\bclass\b/,
      // /\bwhile\b/,
      // /\bfor\b/,
      // /\bif\b/,
      // /\belse\b/,
      // /\btry\b/,
      // /\bexcept\b/,
      // /\bexec\b/,
      // /\beval\b/,
      /[[\]{}]/,           // Square and curly brackets
      /;/,                  // Semicolons
    ];
  
    if (!content || content.trim() === '') {
      return 'Prompt cannot be empty';
    }
    if (content.length > 5000) {
      return 'Prompt exceeds maximum length';
    }
    if (prohibitedWords.some((word) => content.toLowerCase().includes(word))) {
      return 'Prompt contains prohibited words';
    }
    if (codePatterns.some((pattern) => pattern.test(content))) {
      return 'Prompt contains disallowed code patterns';
    }
    if (!/^[\x20-\x7E]*$/.test(content)) { // Checks for printable ASCII characters
      return 'Prompt contains non-printable characters';
    }
    return null; // No errors
  };
  
  const handleSubmit = async () => {
    // const validationError = validatePrompt(prompt);
    // if (validationError) {
    //   alert(validationError); // Display specific validation error
    //   return;
    // }

    if (!prompt) {
      setError('Please enter a prompt');
      setSnackbarOpen(true);
      return;
    }


    if (!selectedFile) {
      setError('Please upload a file');
      setSnackbarOpen(true);
      return;
    }

    setUploading(true);
    setError('');

    try {
      // Get signed URL
      const policy = await getUploadPolicy(
        selectedFile.name,
        selectedFile.type
      );

      // Upload to GCS
      const gcsUrl = await uploadToGCS(policy, selectedFile);

      // Now submit with the GCS URL
      await onSubmit(prompt, gcsUrl);

      // Reset states
      setPrompt('');
      setSelectedFile(null);
    } catch (err) {
      console.error('Upload error:', err);
      setError('Failed to upload file. Please try again.');
      setSnackbarOpen(true);
    } finally {
      setUploading(false);
    }
  };

  return (
    <Box sx={{ textAlign: 'center', padding: { xs: '10px', sm: '20px' } }}>
      {/* Logo and Header */}
      <Box sx={{ textAlign: 'center', mb: { xs: 2, sm: 4 } }}>
        <Box
          component="img"
          src={assembliLogo}
          alt="Assembli Logo"
          sx={{
            width: { xs: 60, sm: 100 },
            height: { xs: 60, sm: 100 },
            bgcolor: '#1a1a8c',
            borderRadius: '50%',
            mx: 'auto',
          }}
        />
        <Box sx={{ color: '#b48478', mt: 2, px: { xs: 2, sm: 0 } }}>
          <Typography variant="h6" sx={{ mb: 1, fontSize: { xs: '1rem', sm: '1.25rem' } }}>
            Welcome to the Assembli MVP Test Site.
          </Typography>
          <Typography variant="h6" sx={{ mb: 1, fontSize: { xs: '1rem', sm: '1.25rem' } }}>
            Please create a job by uploading a set of Construction Plans & a Prompt.
          </Typography>
          <Typography variant="h6" sx={{ mb: 1, fontSize: { xs: '1rem', sm: '1.25rem' } }}>
            A prompt would be an example of a task that you want the Assembli AI Model to perform for you!
          </Typography>
        </Box>
      </Box>

      {/* Prompt Input Area */}
      <Box
        component={Paper}
        elevation={3}
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: { xs: 'center', sm: 'start' },
          p: { xs: 2, sm: 3 },
          width: { xs: '95%', sm: '80%' },
          maxWidth: 600,
          borderRadius: 2,
          mx: 'auto',
        }}
      >
        <TextField
          placeholder="Example Prompt: Produce a complete takeoff for this home."
          multiline
          rows={5}
          variant="outlined"
          fullWidth
          required
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          sx={{
            width: '100%',
            mb: { xs: 2, sm: 0 },
            mr: { xs: 0, sm: 3 },
            '& .MuiOutlinedInput-root': {
              bgcolor: 'white',
              '& fieldset': {
                borderColor: '#b48478',
              },
              '&:hover fieldset': {
                borderColor: '#b48478',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#b48478',
              },
            },
          }}
        />

        {/* Upload Button */}
        <Box
          sx={{
            width: { xs: '100%', sm: 150 },
            height: { xs: 100, sm: 150 },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 2,
            cursor: 'pointer',
          }}
        >
          <input
            accept=".png,.jpg,.jpeg,.gif,.pdf,.doc,.docx"
            style={{ display: 'none' }}
            id="upload-file-input"
            type="file"
            onChange={handleFileChange}
            required
          />
          <label htmlFor="upload-file-input">
            <IconButton sx={{ color: '#1a1a8c' }} component="span">
              <AttachFile sx={{ fontSize: 40 }} />
            </IconButton>
            <Typography
              sx={{
                color: 'Black',
                fontSize: 16,
                fontWeight: 'medium',
                mt: 1,
              }}
            >
              {selectedFile ? truncateFileName(selectedFile.name) : 'Upload your plan'}
            </Typography>
          </label>
        </Box>
      </Box>

      {/* Submit Button */}
      <Box sx={{ mt: { xs: 2, sm: 4 } }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={uploading}
        >
          {uploading ? (
            <>
              <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
              Uploading...
            </>
          ) : (
            'Submit'
          )}
        </Button>
      </Box>

            <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={() => setSnackbarOpen(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={{
              mt: { xs: 2, sm: 4 },
              width: { xs: '60%', sm: '50%', md: '30%' },
            }}
            >
            <Alert onClose={() => setSnackbarOpen(false)} severity="error" sx={{ width: '100%' }}>
              {error}
            </Alert>
            </Snackbar>

            {/* File Preview Dialog */}
      {fileForPreview && (
        <FilePreviewDialog
          open={isDialogOpen}
          file={fileForPreview}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </Box>
  );
};

export default PromptInput;
